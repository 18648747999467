import React from 'react'
import { Link } from 'react-router-dom'
import styles from './defaultLayout.module.scss'

export default function DefaultLayout({ children }) {
  return (
    <div className={styles.pageContainer}>
      <nav>
        <Link to="/">Screenshare</Link>
      </nav>
      <div>
        {children}
      </div>
    </div>
  )
}
