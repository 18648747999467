import React, { useMemo } from "react";
import { useParams } from "react-router";
import LiveChat from "../components/LiveChat/LiveChat";
import LivePlayer from "../components/LivePlayer/LivePlayer";
import StreamAttributes from "../components/StreamAttributes/StreamAttributes";
import DefaultLayout from "../layouts/defaultLayout/defaultLayout";

export default function ChannelPage() {
  const params = useParams()

  const username = useMemo(() => {
    return params.name.toLowerCase();
  }, [params]);

  return (
    <DefaultLayout>
      <h1 style={{ color: "white" }}>{username}'s Screenshare</h1>
      <StreamAttributes username={username} />
      <div style={{ display: 'grid', gridTemplateColumns: '3fr 1fr', width: '75vw', gap: 10 }}>
        <LivePlayer username={username} />
        <LiveChat channelName={username} />
      </div>
    </DefaultLayout>
  );
}
