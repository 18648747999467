import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import APIRequest from '../helpers/request';
import DefaultLayout from '../layouts/defaultLayout/defaultLayout'

export default function IndexPage() {
  const [liveChannels, setLiveChannels] = useState([]);

  useEffect(() => {
    APIRequest.get('/streams').then(e => setLiveChannels(e.data.data))
  }, [])

  return (
    <DefaultLayout>
      {
        liveChannels.map(channel => {
          return (
            <Link to={`/channel/${channel.username}`}>
              <img src={channel.thumbnailUrl} width={256} alt="" />
              <p>{channel.username} - {channel.streamAttributes.title}</p>
            </Link>
          )
        })
      }
    </DefaultLayout>
  )
}
