import axios from 'axios'
import React, { useEffect, useState } from 'react'

export default function StreamAttributes({
  username
}) {
  const [attributes, setAttributes] = useState({})

  useEffect(() => {
    axios.get(`https://api.screenshare.cool/api/viewer/streams/${username}/attributes`).then(e => {
      setAttributes(e.data)
    })
  }, [username])

  return (
    <div>
      <h3>{attributes.title}</h3>
    </div>
  )
}
