import React, { useEffect, useMemo } from 'react'
import OvenPlayer from 'ovenplayer'

export default function LivePlayer({ username }) {

  const sources = useMemo(() => {
    return [
      // {
      //   "file": `wss://oven-m.production.hetzner.servers.cool:3334/app/${channelName}`,
      //   "label": 'Low Latency (WebRTC) Secure',
      //   "type": "webrtc"
      // },
      {
        "file": `wss://live.screenshare.cool/webrtc/playback/${username}`,
        "label": 'HQ Low Latency (WebRTC)',
        "type": "webrtc",
        // image: 'http://oven-m.production.hetzner.servers.cool:20080/app/jordan/thumb.png'
      },
      // {
      //   "file": `wss://live2.screenshare.cool/playback/${username}_preview`,
      //   "label": 'LQ Low Latency (WebRTC)',
      //   "type": "webrtc",
      //   image: 'http://oven-m.production.hetzner.servers.cool:20080/app/jordan/thumb.png'
      // },
      {
        "file": `https://live.screenshare.cool/llhls/playback/${username}/llhls.m3u8`,
        "label": "HQ Reliable (HLS)",
        "type": "hls"
      },
      // {
      //   "file": `https://oven-m.production.hetzner.servers.cool/hls/app/${username}_preview/playlist.m3u8`,
      //   "label": "LQ Reliable (HLS)",
      //   "type": "hls"
      // }
    ]
  }, [username])

  useEffect(() => {
    OvenPlayer.create('video', {
      controls: true,
      autoPlay: true,
      showBigPlayButton: false,
      disableSeekUI: true,
      sources
    })
  }, [sources])

  return (
    <div id="video"></div>
  )
}
