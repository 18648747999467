import React, { useCallback, useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import Echo from 'laravel-echo';
window.Pusher = require('pusher-js');

const LiveChatMessage = ({
  message = {}
}) => {
  return (
    <p style={{lineHeight: 0.5}}><strong>{message.name}</strong>: {message.message}</p>
  )
}

export default function LiveChat({
  channelName
}) {
  const [messages, setMessages] = useState([])
  const [username, setUsername] = useState('');
  const [pendingMessage, setPendingMessage] = useState('')
  const [listening, setListening] = useState(false)

  const appendToMessages = useCallback((newMsg) => {
    setMessages(_old => [..._old, newMsg.message])
  }, [setMessages])

  useEffect(() => {
    if (listening) return
    let laravelEcho = new Echo({
      broadcaster: 'pusher',
      key: 'app-key',
      wsHost: 'ws.screenshare.cool',
      forceTLS: true,
      encrypted: false,
      disableStats: true,
      enabledTransports: ['ws', 'wss'],
      authEndpoint: 'https://api.screenshare.cool/broadcasting/auth'
    });
    laravelEcho.channel(`${channelName}.messages.new`)
      .listen('NewChannelMessage', (e) => {
        appendToMessages(e);
      });

    setListening(true)
  }, [listening, appendToMessages, channelName])

  const sendMessage = useCallback(() => {
    axios.post('https://api.screenshare.cool/api/messages', {
      name: username,
      channel_name: channelName,
      message: pendingMessage
    }).then(e => {
      setPendingMessage('')
    })
  }, [username, pendingMessage, setPendingMessage, channelName]);

  const messageDisplay = useMemo(() => {
    return messages.reverse()
  }, [messages])

  return (
    <div style={{height: 'inherit', backgroundColor: 'gray', display: 'grid', gridTemplateRows: 'max-content auto max-content', overflow: 'auto', maxHeight: '64vh'}}>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <label>Your Name (Required for chatting)</label>
        <textarea style={{color: 'black'}} value={username} onChange={_ => setUsername(_.target.value)}></textarea>
      </div>
      <div style={{overflowY: 'scroll', overflow: 'auto'}}>
        {
          messageDisplay.map(msg => {
            return (
              <LiveChatMessage message={msg} />
            )
          })
        }
      </div>
      <div style={{display: 'grid', gridTemplateColumns: 'auto max-content'}}>
        <textarea
          onKeyDown={_ => _.key === 'Enter' ? sendMessage(_) : console.log(_.key)}
          disabled={!username} style={{color: 'black'}} value={pendingMessage} onChange={_ => setPendingMessage(_.target.value)}></textarea>
        <button disabled={!username} style={{color: 'black'}} onClick={sendMessage}>Send</button>
      </div>
    </div>
  )
}
