import axios from "axios";
import { useEffect, useState } from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes
} from "react-router-dom";
import IndexPage from "./pages";

import ChannelPage from './pages/channel'

axios.defaults.withCredentials = true

const RouterComp = () => {
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        axios.get('https://api.screenshare.cool/sanctum/csrf-cookie').then((res) => {
            setLoaded(true)
        })
    }, [])
    
    return (
        <Router>
            <Routes>
                {
                    loaded ? <>
                        <Route path="/channel/:name" element={<ChannelPage />} />
                        <Route path="/" element={<IndexPage />} />
                    </> : null
                }
            </Routes>
        </Router>
    )
}

export default RouterComp;
